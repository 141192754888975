import React, { useState } from 'react';
import axios from 'axios';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const PricePlanEditForm = ({ pricePlan, courseId, onPricePlanUpdated }) => {
  const [title, setTitle] = useState('');
  const [price, setPrice] = useState(pricePlan.price);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(pricePlan.image_url);
  const [loading, setLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      alert('Please select an image first');
      return;
    }

    setLoading(true);
    const params = {
      Bucket: 'copybrain',
      Key: `${Date.now()}_${image.name}`,
      Body: image,
      ContentType: image.type,
      // ACL: 'public-read',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const url = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      setImageUrl(url);
      setLoading(false);
    } catch (error) {
      console.error('Error uploading image:', error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await axios.put(
        `${API_URL}/courses/${courseId}/price_plans/${pricePlan.id}`,
        {
          price_plan: {
            title,
            price,
            image_url: imageUrl
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      onPricePlanUpdated(response.data);
    } catch (error) {
      console.error('Error updating price plan:', error);
    }
  };

  return (
    <form className="lesson_edit_form" onSubmit={handleSubmit}>
      <div>
        <label>Title:</label>
        <input
          type="text"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div>
        <label>Price:</label>
        <input
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
        />
      </div>
      <div>
        <label>Image:</label>
        <input type="file" accept="image/*" onChange={handleImageChange} />
        <button type="button" onClick={handleUpload} disabled={loading}>
          {loading ? 'Uploading...' : 'Upload Image'}
        </button>
        {imageUrl && <img src={imageUrl} alt="Price Plan" width="100" />}
      </div>
      <button type="submit">Update Price Plan</button>
    </form>
  );
};

export default PricePlanEditForm;
