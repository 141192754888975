import React, { useState, useRef } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const UploadVideoForm = () => {
  const API_VIDEO_URL = process.env.REACT_APP_API_VIDEO_URL;
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState(""); 
  const [email, setEmail] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !email) {
      setStatus("Please provide both a file and an email.");
      return;
    }

    setLoading(true);
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); 
    }, 150);

    setStatus("Uploading...");
    setVideoId(null);
    setTranslatedText("");

    // Отправляем на Rails
    const formData = new FormData();
    formData.append("video[file]", file);
    formData.append("video[user_email]", email);
    formData.append("translation[language]", language);

    const response = await fetch(`${API_VIDEO_URL}/videos`, {
      method: "POST",
      body: formData
    });

    clearInterval(interval);
    setProgress(100);
    setLoading(false);

    const data = await response.json();
    if (response.ok) {
      setVideoId(data.video_id);
      setStatus("Processing in background...");
      setShowModal(true);
      setFile(null);
      setEmail("");
      setLanguage("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setStatus(`Error: ${data.error || 'Something went wrong'}`);
    }
  };

  const handleCheckStatus = async () => {
    if (!videoId) return;
    const response = await fetch(`${API_VIDEO_URL}/videos/${videoId}`);
    const data = await response.json();
    setStatus(data.status);

    if (data.status === "done") {
      setTranslatedText(data.translated_text || "");
    }
  };

  return (
    <div className="container video_uploader_section">
      <h1>CopyBrain: AI-Agent for Social Media Content Localization</h1>

      <h2>Seamless Content Localization for Social Media</h2>
      <div className="video_uploader_top_desc">
        Want your content to break language barriers effortlessly? CopyBrain is an AI-powered vertical agent that localizes social media content with precision.
      </div>

      <h3>How It Works</h3>
      <div className="video_uploader_videos_desc">
        1. Original Video – Your unedited content. <br />
        2. Subtitle Removal – We clean up the original subtitles. <br />
        3. Full Localization – We translate the text, sync playback speed to match the new audio, and even clone the original voice for a seamless experience.
      </div>

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Original Video</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056506130?h=4c4f88d1b7&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Subtitle Removal</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505941?h=bdff3a57b0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Full Localization</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505821?h=7037d8fd67&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <hr />

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Original Video</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505340?h=5e1dba1877&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Subtitle Removal</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505373?h=37686025a1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Full Localization</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505863?h=11f7336d87&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <hr />

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Original Video</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505232?h=3dd1e42aa8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Subtitle Removal</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056506009?h=da30c56d55&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Full Localization</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505760?h=b4af8d6e03&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <hr />

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Original Video without subtitle</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505622?h=5df8eee73b&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Full Localization with subtitles</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1056505694?h=421747c0a3&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <h3>Try It Yourself!</h3>

      <div className="video_uploader_videos_desc">
        Upload your video, choose a target language, and enter your email—we’ll send you the localized version.
      </div>

      <div className="video_uploader_input_container">
        <form onSubmit={handleSubmit}>
          <input type="file" accept="video/*" onChange={handleFileChange} ref={fileInputRef} />

          <select value={language} onChange={handleLanguageChange}>
            <option value="">Select a language to translate to</option>
            <option value="es">Spanish</option>
            <option value="de">German</option>
            <option value="en">English</option>
            <option value="it">Italian</option>
            <option value="fr">French</option>
          </select>

          <input type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />

          <button type="submit">Upload</button>
        </form>

        {loading && (
          <div className="loading-progress-bar">
            <ProgressBar animated now={progress} label={`${progress}%`} />
          </div>
        )}
      </div>

      <h3>Processing Time & Our “Supercomputer”</h3>

      <div className="video_uploader_videos_desc">
        Here’s the thing: We don’t (yet!) have access to a super-powerful GPU farm. Instead, our AI runs on a home-built server, lovingly assembled with whatever tech we could get our hands on. Processing takes around 7 minutes.
        <br /><br />
        Either way, your content will soon be speaking a whole new language! 🚀
      </div>

      <h3>Stay in Touch</h3>

      <div className="video_uploader_videos_desc">
        Have questions, feedback, or want to invest in our strartup?
        <br /><br />
        📩 Email: <a href="mailto:brain@copybrain.app">brain@copybrain.app</a>
        <br /> 
        💬 LinkedIn: <a href="https://www.linkedin.com/company/copy-brain">Copy Brain</a>        
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="mt-0">Video Received!</h2>
            <p>We have received your video. You will receive the translated version via email shortly.</p>
            <button onClick={() => setShowModal(false)} className="modal-billing-button">Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadVideoForm;
