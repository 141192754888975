import React, { useState, useRef } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import ProgressBar from 'react-bootstrap/ProgressBar';
import ImagePreview from './ImagePreview';

const ImageUploader = ({ onAnalysisComplete, onSaveComplete, projectId }) => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const { t, i18n } = useTranslation();
  const token = localStorage.getItem('token');
  
  // Используем useRef для хранения интервалов, чтобы их легче было очистить
  const intervalsRef = useRef([]);
  const timeoutsRef = useRef([]);
  
  // Функция для очистки всех интервалов и таймаутов
  const clearAllTimers = () => {
    intervalsRef.current.forEach(clearInterval);
    timeoutsRef.current.forEach(clearTimeout);
    intervalsRef.current = [];
    timeoutsRef.current = [];
  };

  const handleImageChange = (event) => {
    setImage(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!image) {
      alert('Please select an image first');
      return;
    }

    setLoading(true);
    setProgress(0);
    
    // Очищаем предыдущие таймеры, если они были
    clearAllTimers();

    // Улучшенная имитация прогресса загрузки
    // Начальный этап - быстрое заполнение до 40%
    const initialInterval = setInterval(() => {
      setProgress((prev) => {
        if (prev < 40) return prev + 2;
        clearInterval(initialInterval);
        return prev;
      });
    }, 100);
    intervalsRef.current.push(initialInterval);
    
    // Средний этап - более медленное заполнение до 70%
    const middleTimeout = setTimeout(() => {
      const middleInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 70) return prev + 0.5;
          clearInterval(middleInterval);
          return prev;
        });
      }, 300);
      intervalsRef.current.push(middleInterval);
    }, 3000);
    timeoutsRef.current.push(middleTimeout);
    
    // Финальный этап - очень медленное заполнение до 90%
    const finalTimeout = setTimeout(() => {
      const finalInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 90) return prev + 0.2;
          clearInterval(finalInterval);
          return prev;
        });
      }, 800);
      intervalsRef.current.push(finalInterval);
    }, 8000);
    timeoutsRef.current.push(finalTimeout);

    try {
      // Создаем FormData для отправки файла
      const formData = new FormData();
      formData.append('image', image);
      formData.append('project_id', projectId);
      formData.append('language', i18n.language); // Добавляем текущий язык

      // Настройка для отслеживания прогресса загрузки (опционально)
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: (progressEvent) => {
          // Используем события реальной загрузки только для логирования
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          console.log(`Actual upload progress: ${percentCompleted}%`);
          // Не обновляем UI здесь, чтобы не мешать нашей плавной анимации
        }
      };

      // Отправляем файл на бекенд
      const response = await axios.post(`${API_URL}/uploads/image`, formData, config);
      
      // Завершаем прогресс с анимацией заполнения до 100%
      const finishInterval = setInterval(() => {
        setProgress((prev) => {
          if (prev < 100) return prev + 2;
          clearInterval(finishInterval);
          return 100;
        });
      }, 50);
      intervalsRef.current.push(finishInterval);
      
      // Получаем данные из ответа
      const { image_url, analysis_result } = response.data;
      
      // Вызываем колбэки с полученными данными
      onAnalysisComplete(analysis_result, image_url);
      onSaveComplete({
        image_url,
        analysis_result
      });
      
    } catch (error) {
      console.error('Error uploading image or analyzing:', error);
      alert('Error uploading image or analyzing: ' + (error.response?.data?.error || error.message));
    } finally {
      // Очищаем все таймеры
      clearAllTimers();
      setLoading(false);
    }
  };

  return (
    <div className="project_form mw-100 mt-3">
      <label htmlFor="project-name">{t('upload_image_for_analysis')}</label>
      <span className="dashboard_form_label_subtext">
        {t('upload_image_for_analysis_text')}
      </span>
      <div className="mt-2 mb-4" style={{color: "white"}}>
        <input type="file" accept="image/*" onChange={handleImageChange} />
      </div>
      {loading ? (
        <div className="loading-progress-bar mt-4">
          <ProgressBar animated now={progress} label={`${Math.floor(progress)}%`} />
        </div>
      ) : (
        <button type="button" className="save_button darker_bg w-100" onClick={handleUpload} disabled={loading}>
          {loading ? t('analyzing') : t('upload_analyze')}
        </button>
      )}
    </div>
  );
};

export default ImageUploader;