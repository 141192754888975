import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import AuthorSelect from './AuthorSelect';
import CategorySelect from './CategorySelect';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const ArticleForm = ({ onArticleCreated }) => {
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [shortDescription, setShortDescription] = useState('');
  const [description, setDescription] = useState('');
  const [status, setStatus] = useState('draft');
  const [publishedAt, setPublishedAt] = useState('');
  const [authorId, setAuthorId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const quillRef = useRef();

  const token = localStorage.getItem('token');

  const API_URL = process.env.REACT_APP_API_URL;

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newArticle = {
      title,
      short_description: shortDescription,
      description,
      status,
      published_at: publishedAt,
      author_id: authorId,
      category_id: categoryId,
    };

    try {
      const response = await axios.post(
        `${API_URL}/articles`,
        {
          newArticle
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      onArticleCreated(response.data);
      setTitle('');
      setShortDescription('');
      setDescription('');
      setStatus('draft');
      setPublishedAt('');
      setAuthorId('');
      setCategoryId('');
    } catch (error) {
      console.error('Error creating article:', error);
    }
  };

  const handleImageUpload = async (file) => {
    const params = {
      Bucket: 'copybrain',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      // ACL: 'public-read',
    };

    try {
      const data = await s3Client.send(new PutObjectCommand(params));
      const imageUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      return imageUrl;
    } catch (error) {
      return null;
    }
  };

  const imageHandler = async () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      if (file) {
        const imageUrl = await handleImageUpload(file);
        if (imageUrl) {
          const quill = quillRef.current.getEditor();
          const range = quill.getSelection();
          if (range) {
            quill.insertEmbed(range.index, 'image', imageUrl);
          }
        }
      }
    };
  };

  const modules = useMemo(() => ({
    toolbar: {
      container: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
        ['link', 'image'],
        ['clean']
      ],
      handlers: {
        image: imageHandler
      }
    }
  }), [])

  const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    'link', 'image'
  ];

  const handleDescriptionChange = (value) => {
    setDescription(value);
  };

  return (
    <div className="dashboard_form_container">
      <Link className="post_container_back_link with_bg" to={`/articles`}>{t('back')}</Link>

      <h1>Новая статья</h1>

      <form className="project_form mt-5" onSubmit={handleSubmit}>
        <div>
          <label>Заголовок:</label>
          <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} required />
        </div>
        <div>
          <label>Короткое описание</label>
          <textarea value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} required />
        </div>
        <div>
          <label>Описание</label>
          <ReactQuill
            ref={quillRef}
            value={description}
            onChange={handleDescriptionChange}
            modules={modules}
            formats={formats}
          />
        </div>
        <div>
          <label>Статус</label>
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="draft">Черновик</option>
            <option value="published">Опубликовано</option>
            <option value="archived">Архив</option>
          </select>
        </div>
        <div>
          <label>Дата публикации</label>
          <input type="datetime-local" value={publishedAt} onChange={(e) => setPublishedAt(e.target.value)} />
        </div>
        <div>
          <AuthorSelect authorId={authorId} setAuthorId={setAuthorId} />
        </div>
        <div>
          <CategorySelect categoryId={categoryId} setCategoryId={setCategoryId} />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Сохраняем...' : 'Сохранить'}
        </button>
      </form>
    </div>
  );
};

export default ArticleForm;
