import React, { useState, useRef } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';

const UploadVideoFormLv = () => {
  const API_VIDEO_URL = process.env.REACT_APP_API_VIDEO_URL;
  const [file, setFile] = useState(null);
  const [language, setLanguage] = useState(""); 
  const [email, setEmail] = useState("");
  const [videoId, setVideoId] = useState(null);
  const [status, setStatus] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [showModal, setShowModal] = useState(false);
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file || !email) {
      setStatus("Please provide both a file and an email.");
      return;
    }

    setLoading(true);
    setProgress(0);
    const interval = setInterval(() => {
      setProgress((prev) => (prev + 1 <= 100 ? prev + 1 : 100)); 
    }, 150);

    setStatus("Uploading...");
    setVideoId(null);
    setTranslatedText("");

    // Отправляем на Rails
    const formData = new FormData();
    formData.append("video[file]", file);
    formData.append("video[user_email]", email);
    formData.append("translation[language]", language);

    const response = await fetch(`${API_VIDEO_URL}/videos`, {
      method: "POST",
      body: formData
    });

    clearInterval(interval);
    setProgress(100);
    setLoading(false);

    const data = await response.json();
    if (response.ok) {
      setVideoId(data.video_id);
      setStatus("Processing in background...");
      setShowModal(true);
      setFile(null);
      setEmail("");
      setLanguage("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      setStatus(`Error: ${data.error || 'Something went wrong'}`);
    }
  };

  const handleCheckStatus = async () => {
    if (!videoId) return;
    const response = await fetch(`${API_VIDEO_URL}/videos/${videoId}`);
    const data = await response.json();
    setStatus(data.status);

    if (data.status === "done") {
      setTranslatedText(data.translated_text || "");
    }
  };

  return (
    <div className="container video_uploader_section"> 
      <h1>CopyBrain: AI Aģents Sociālo Mediju Satura Lokalizācijai</h1>
      <h2>Vienkārša Satura Lokalizācija Sociālajiem Medijiem</h2>
      <div className="video_uploader_top_desc">
        Vai vēlaties, lai jūsu saturs viegli pārvarētu valodas barjeras? CopyBrain ir AI risinājums, kas precīzi lokalizē sociālo mediju saturu.
      </div>

      <h3>Kā Tas Darbojas</h3>

      <div className="video_uploader_videos_desc">
        1. Oriģinālais Video – Jūsu nesagrozītais saturs. <br />
        2. Subtitru Noņemšana – Mēs attīrām sākotnējos subtitrus. <br />
        3. Pilna Lokalizācija – Mēs tulkojam tekstu, sinhronizējam atskaņošanas ātrumu ar jauno audio un pat klonējam oriģinālo balsi, lai nodrošinātu vienmērīgu pieredzi.
      </div>

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Oriģinālais Video</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061725602?h=95bb2667bc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Subtitru Noņemšana</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061726354?h=048450dd28&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Pilna Lokalizācija</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061723253?h=62264ca4f1&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>
      
      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Oriģinālais Video</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061725795?h=038701cac5&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Subtitru Noņemšana</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061726079?h=1649457a2e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Pilna Lokalizācija</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061722939?h=4887e45c70&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <div className="video_uploader_items_row">
        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Oriģinālais Video bez subtitriem</div>

          <div className="video_uploader_items_item_text"></div>

          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061725443?h=a2530e8bd0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>

        <div className="video_uploader_items_item">
          <div className="video_uploader_items_item_heading">Pilna Lokalizācija ar subtitriem</div>

          <div className="video_uploader_items_item_text"></div>
          
          <div className="video_uploader_items_item_video">
            <iframe
              src="https://player.vimeo.com/video/1061723506?h=f5ef913fd2&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              width="324"
              height="486"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture; clipboard-write; encrypted-media"
              title="2_sample"
            ></iframe>
          </div>
        </div>
      </div>

      <h3>Pamēģiniet Paši!</h3>

      <div className="video_uploader_videos_desc">
        Augšupielādējiet savu video, izvēlieties mērķa valodu un ievadiet savu e-pastu – mēs jums nosūtīsim lokalizēto versiju.
      </div>

      <div className="video_uploader_input_container">
        <form onSubmit={handleSubmit}>
          <input type="file" accept="video/*" onChange={handleFileChange} ref={fileInputRef} />

          <select value={language} onChange={handleLanguageChange}>
            <option value="">Izvēlieties valodu tulkojumam</option>
            <option value="es">Spāņu</option>
            <option value="de">Vācu</option>
            <option value="en">Angļu</option>
            <option value="it">Itāļu</option>
          </select>

          <input type="email" placeholder="Ievadiet savu e-pastu" value={email} onChange={handleEmailChange} />

          <button type="submit">Augšupielādēt</button>
        </form>

        {loading && (
          <div className="loading-progress-bar">
            <ProgressBar animated now={progress} label={`${progress}%`} />
          </div>
        )}
      </div>

      <h3>Apstrādes Laiks & Mūsu “Superdators”</h3>

      <div className="video_uploader_videos_desc">
        Lūk, kā ir: Mums vēl nav pieejams jaudīgs GPU serveru parks. Tā vietā mūsu AI darbojas uz mājās veidota servera, kas samontēts no labākajām pieejamajām tehnoloģijām. Apstrāde aizņem apmēram 7 minūtes.
        <br /><br />
        Jebkurā gadījumā jūsu saturs drīz runās jaunā valodā! 🚀
      </div>

      <h3>Palieciet Saziņā</h3>

      <div className="video_uploader_videos_desc"> 
        Vai jums ir jautājumi, atsauksmes vai vēlaties ieguldīt mūsu jaunuzņēmumā? 
        <br /><br /> 
        📩 E-pasts: <a href="mailto:brain@copybrain.app">brain@copybrain.app</a> 
        <br /> 
        💬 LinkedIn: <a href="https://www.linkedin.com/company/copy-brain">Copy Brain</a> 
      </div>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2 className="mt-0">Video Saņemts!</h2>
            <p>Mēs esam saņēmuši jūsu video. Jūs drīz saņemsiet tulkoto versiju pa e-pastu.</p>
            <button onClick={() => setShowModal(false)} className="modal-billing-button">Aizvērt</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UploadVideoFormLv;
