import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../AuthContext';
import { useTranslation } from 'react-i18next';
import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';

const s3Client = new S3Client({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  },
});

const LessonPage = () => {
  const { courseId, lessonId } = useParams();
  const [lesson, setLesson] = useState(null);
  const [quests, setQuests] = useState([]);
  const [answers, setAnswers] = useState({});
  const [newAnswer, setNewAnswer] = useState({});
  const [validationError, setValidationError] = useState({});
  const [user, setUser] = useState(null);
  const [userPlanId, setUserPlanId] = useState(null);
  const [courseTitle, setCourseTitle] = useState('');
  const [openQuestIds, setOpenQuestIds] = useState([]);
  const [feedbacks, setFeedbacks] = useState({});

  const { t } = useTranslation();

  const navigate = useNavigate();
  const { user: authUser, logout } = useAuth(); // Using user from context
  const token = localStorage.getItem('token');
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchLessonAndQuests = async () => {
      try {
        const responseLesson = await axios.get(`${API_URL}/education/courses/${courseId}/lessons/${lessonId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLesson(responseLesson.data);
        setCourseTitle(responseLesson.data.course_title);

        const responseQuests = await axios.get(`${API_URL}/education/courses/${courseId}/lessons/${lessonId}/quests`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const activeQuests = responseQuests.data.filter(quest => quest.active);
        setQuests(activeQuests.sort((a, b) => a.order_number - b.order_number));

        const initialAnswers = {};
        activeQuests.forEach(quest => {
          initialAnswers[quest.id] = '';
          fetchAnswersForQuest(quest.id);
        });
        setNewAnswer(initialAnswers);

        const responseUser = await axios.get(`${API_URL}/users/me/course/${courseId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(responseUser.data.user);
        setUserPlanId(responseUser.data.price_plan_id);

      } catch (error) {
        console.error('Error fetching lesson or quests:', error);
      }
    };

    const fetchAnswersForQuest = async (questId) => {
      try {
        const responseAnswer = await axios.get(`${API_URL}/education/courses/${courseId}/lessons/${lessonId}/quests/${questId}/answers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (responseAnswer.data) {
          setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questId]: responseAnswer.data,
          }));

          fetchFeedbackForAnswer(questId, responseAnswer.data.id);
        }
      } catch (error) {
        console.error(`Error fetching answer for quest ${questId}:`, error);
      }
    };

    if (authUser) {
      fetchLessonAndQuests();
    } else {
      navigate('/login');
    }
  }, [authUser, courseId, lessonId, token, navigate]);

  const fetchFeedbackForAnswer = async (questId, answerId) => {
    try {
      const response = await axios.get(`${API_URL}/quests/${questId}/answers/${answerId}/feedbacks/${answerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data) {
        setFeedbacks(prevFeedbacks => ({
          ...prevFeedbacks,
          [answerId]: response.data,
        }));
      }
    } catch (error) {
      console.error(`Error fetching feedback for answer ${answerId}:`, error);
    }
  };

  const handleQuestToggle = (questId) => {
    setOpenQuestIds(prevOpenQuestIds =>
      prevOpenQuestIds.includes(questId)
        ? prevOpenQuestIds.filter(id => id !== questId)
        : [...prevOpenQuestIds, questId]
    );
  };

  const handleAnswerChange = (questId, type, value) => {
    setNewAnswer(prev => ({
      ...prev,
      [questId]: {
        ...prev[questId],
        [type]: value,
      }
    }));
    if (validationError[questId]) {
      setValidationError(prev => ({ ...prev, [questId]: '' }));
    }
  };

  const validateCode = (questId) => {
    const quest = quests.find(q => q.id === questId);
    if (quest.code && newAnswer[questId]?.code !== quest.code) {
      setValidationError(prev => ({ ...prev, [questId]: t('incorrect_code') }));
      return false;
    }
    return true;
  };

  const handleFileUpload = async (file) => {
    const params = {
      Bucket: 'copybrain',
      Key: `${Date.now()}_${file.name}`,
      Body: file,
      ContentType: file.type,
      // ACL: 'public-read',
    };

    try {
      await s3Client.send(new PutObjectCommand(params));
      const fileUrl = `https://${params.Bucket}.s3.${process.env.REACT_APP_AWS_REGION}.amazonaws.com/${params.Key}`;
      return fileUrl;
    } catch (error) {
      console.error('Error uploading file:', error);
      return null;
    }
  };

  const handleAnswerSubmit = async (questId) => {
    if (validateCode(questId)) {
      const formData = new FormData();
      if (newAnswer[questId].description) formData.append('description', newAnswer[questId].description);
      if (newAnswer[questId].code) formData.append('code', newAnswer[questId].code);

      if (newAnswer[questId].file) {
        const fileUrl = await handleFileUpload(newAnswer[questId].file);
        if (fileUrl) {
          formData.append('file', fileUrl);  // Отправляем ссылку на файл вместо самого файла
        } else {
          console.error('File upload failed');
          return;
        }
      }

      try {
        // Проверяем id тарифного плана пользователя
        const pointsMultiplier = userPlanId === 4 ? 2 : 1; // Если тариф "АЛИСА", удваиваем очки
        formData.append('points_multiplier', pointsMultiplier);

        const response = await axios.post(`${API_URL}/education/courses/${courseId}/lessons/${lessonId}/quests/${questId}/answers`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response.data && response.data.code === newAnswer[questId].code) {
          const quest = quests.find(q => q.id === questId);
          setUser(prevUser => ({
            ...prevUser,
            points: prevUser.points + quest.points * pointsMultiplier, // Учитываем множитель очков
          }));
        }

        setAnswers(prev => ({
          ...prev,
          [questId]: response.data,
        }));
        setNewAnswer(prev => ({
          ...prev,
          [questId]: {
            description: '',
            code: '',
            file: null,
          },
        }));
      } catch (error) {
        console.error('There was an error submitting the answer!', error);
      }
    }
  };

  const pointsMultiplier = userPlanId === 4 ? 2 : 1;

  const showAnswerForm = userPlanId !== 1;

  if (!lesson) return <div>Loading...</div>;

  return (
    <div className="education_lesson_container">
      <div className="user_points_container">
        <img className="user_points_img left" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/coin.webp" />

        <div className="user_points_heading">
          {t('you_earned')}

          <span className="user_points_number">
            {user ? user.points : 'Loading...'} 
          </span>

          {t('brain_points')}
        </div>

        <img className="user_points_img right" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/coin.webp" />
      </div>

      <div className="education_lesson_inner">
        <Link to={`/education/courses/${courseId}`} className="education_lesson_back_link">
          <img className="education_lesson_img" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/back.webp" />
        </Link>

        <div className="education_lesson_content_container">
          <div className="education_lesson_img_container">
            <img className="education_lesson_img" src="https://gnot-me-public.s3.eu-north-1.amazonaws.com/a-futuristic-abstract-concept-of-a-tunnel-that-lea-0u3XUFjHRn2wwlhOlF5pLQ-LgfsJ-oKSzylZPL8Dq6zsw.webp" />
          </div>

          <div className="education_lesson_course_name">
            {courseTitle}
          </div>

          <h1 className="education_lesson_name">{lesson.title}</h1>

          <p dangerouslySetInnerHTML={{ __html: lesson.description }} className="education_lesson_desc"></p>

          <div dangerouslySetInnerHTML={{ __html: lesson.video_url }} className="education_lesson_video">
          </div>

          <h2 className="education_lesson_quests_heading">{t('quests')}</h2>

          <div className="education_lesson_quests_container">
            {quests.map(quest => (
              <div key={quest.id} className={answers[quest.id] ? 'education_lesson_quests_item done' : 'education_lesson_quests_item'}>
                <div className={`education_lesson_quests_item_heading ${openQuestIds.includes(quest.id) ? 'open' : ''}`}
                    onClick={() => handleQuestToggle(quest.id)}>
                  {quest.title}
                </div>

                {openQuestIds.includes(quest.id) && (
                  <div className="education_lesson_quests_item_content">
                    <p dangerouslySetInnerHTML={{ __html: quest.description }} className="education_lesson_quests_item_desc"></p>

                    {answers[quest.id] ? (
                      <div>
                        <div className="education_lesson_quests_item_accepted">
                          {quest.needs_approval && !answers[quest.id].accepted 
                            ? t('thanks_answer_accept_awaiting')
                            : t('thanks_answer_accepted')}
                          {!quest.needs_approval || answers[quest.id].accepted 
                            ? `${t('you_received')} ${quest.points * pointsMultiplier} ${t('received_points')}`
                            : ''}
                        </div>

                        <div>
                          <div className="education_lesson_quests_item_accepted no_bg mt-3">{t('your_answer')}</div>

                          <div className="education_lesson_quests_item_answer_text">
                            <p dangerouslySetInnerHTML={{ __html: answers[quest.id].description ? answers[quest.id].description.replace(/\r\n/g, '<br/>') : '' }}></p>
                          </div>

                          {answers[quest.id].code && <div className="education_lesson_quests_item_answer_text">
                            {answers[quest.id].code}
                          </div>}

                          {answers[quest.id].file && <div className="education_lesson_quests_item_answer_text">
                            <a href={answers[quest.id].file} target="_blank" rel="noopener noreferrer">{t('see_screenshot')}</a>
                          </div>}

                          {feedbacks[answers[quest.id].id] && (
                            <div className="education_lesson_quests_item_feedback">
                              <div className="education_lesson_quests_item_feedback_heading">{t('feedback_for_quest')}</div>

                              <p dangerouslySetInnerHTML={{ __html: feedbacks[answers[quest.id].id].description ? feedbacks[answers[quest.id].id].description.replace(/(?:\r\n|\r|\n)/g, '<br/>') : '' }} className="education_lesson_quests_item_feedback_text"></p>
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      showAnswerForm && (
                        <div className="education_lesson_quests_form_container">
                          <div className="education_lesson_quests_form_heading">
                            {t('your_answer_for_quest')}
                          </div>

                          {quest.code && (
                            <div>
                              <label>{t('enter_code')}</label>
                              <input
                                type="text"
                                value={newAnswer[quest.id]?.code || ''}
                                onChange={e => handleAnswerChange(quest.id, 'code', e.target.value)}
                              />
                              {validationError[quest.id] && <p style={{ color: 'red', marginTop: '8px' }}>{validationError[quest.id]}</p>}
                            </div>
                          )}
                          {quest.has_text && (
                            <div>
                              <label>{t('answer_text')}</label>
                              <textarea
                                value={newAnswer[quest.id]?.description || ''}
                                onChange={e => handleAnswerChange(quest.id, 'description', e.target.value)}
                              />
                            </div>
                          )}
                          {quest.has_screenshot && (
                            <div className="mt-2">
                              <label>{t('screenshot')}</label>
                              <input
                                type="file"
                                onChange={e => handleAnswerChange(quest.id, 'file', e.target.files[0])}
                              />
                            </div>
                          )}
                          <button onClick={() => handleAnswerSubmit(quest.id)}>{t('send')}</button>
                        </div>
                      )
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LessonPage;
